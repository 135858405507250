import React from 'react';

import './Foot.scss';

function Foot() {
    return (
        <footer class="foot">
            <p class="foot__txt">
                created by
                <a href="https://github.com/antozr" class="foot__lien">
                    anto__dev
                </a>
                - devChallenges.io
            </p>
        </footer>
    );
}

export default Foot;
